

















































import { Component, Vue } from "vue-property-decorator";
import Tree from "@/app/shared/components/tree/tree.vue";

import { CreateDocumentRoute } from "@/app/domains/document/document.route";
import { EditDocumentRoute } from "@/app/domains/document/document.route";

import Node from "@/app/shared/components/tree/models/node.model";
import ContextMenuModel from "@/app/shared/components/tree/models/context-menu.model";
import Structure from "@/app/domains/document/models/structure.model";
import TreeDropDataModel from "@/app/shared/components/tree/models/tree-drop.model";

// import TreeData from "./tree.data";

@Component({
  components: {
    Tree,
  },

  async beforeRouteUpdate(to, from, next): Promise<void> {
    next(); // continue main redirect
    const instance = this as Project;

    if (!to.params.id) {
      instance.$refs.tree.selectNode(null);
    }

    if (to.params.project.toString() !== from.params.project.toString()) {
      await instance.getProjectDocuments(to.params.project);
      instance.selectFirstDocument();
    } else {
      const id = parseInt(to.params.id) || instance.treeData?.[0].id;
      if (instance.$refs.tree.selectedNodeId === id) return;
      instance.openAndSelectNode(id);
      instance.goToDocumentById(id);
    }
  },
})
export default class Project extends Vue {
  treeData: Array<Structure> = []; //treeData;
  treeSearchValue: string | null = null;
  contextMenuItems: Array<string> = ["Edit", "Unlink"];

  $refs!: {
    tree: Tree;
  };

  private get project(): string {
    return this.$route.params.project;
  }

  private get path(): string {
    return "#/projects/" + this.project + "/";
  }

  createDocument(data: Node): void {
    if (CreateDocumentRoute.name)
      this.$router.push({
        name: CreateDocumentRoute.name,
        params: {
          projectId: this.project,
          parentId: data.id.toString(),
        },
      });
  }

  private contextMenuClick(params: ContextMenuModel<Structure>): void {
    switch (params.value) {
      case "Edit":
        this.$router.push({
          name: EditDocumentRoute.name,
          params: {
            id: params.node.id.toString(),
            projectId: params.node.projectId.toString(),
          },
        });
        break;
      case "Unlink":
        this.deleteNode(params);
        break;

      default:
        break;
    }
  }

  private search(): void {
    const id = this.$refs.tree.search(this.treeSearchValue);
    if (id) this.goToDocumentById(id);
  }

  private openAndSelectNode(id: number): void {
    this.$refs.tree.openAndSelectNode(id);
  }

  private selectFirstDocument(): void {
    const [firstDocument] = this.treeData;
    if (firstDocument && firstDocument.id) {
      this.goToDocumentById(firstDocument.id);
      this.openAndSelectNode(firstDocument.id);
    }
  }

  private goToDocumentById(id: number): void {
    this.$router
      .push({
        path: `/projects/${this.project}/${id}`,
      })
      .catch((error) => error);
  }

  private async syncTree(): Promise<void> {
    try {
      await this.$store.dispatch("settingsModule/modifyStructure", {
        structure: this.treeData,
        projectId: this.project,
      });
      this.$message({
        message: "Project updated successfully",
        type: "success",
      });
    } catch (error) {
      console.error(error);
    }
  }

  private async deleteNode(params: ContextMenuModel<Structure>): Promise<void> {
    try {
      await this.$confirm(
        "This will permanently delete the document. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
    } catch (error) {
      return;
    }

    let { node, parent } = params;
    const parentNodes = parent ? parent.children : this.treeData;
    const index = parentNodes.findIndex((c) => c.id === node.id);
    if (index >= 0) parentNodes.splice(index, 1);
    if (this.treeData.length) {
      this.selectFirstDocument();
      this.openAndSelectNode(this.treeData[0].id);
    }

    this.syncTree();
  }

  private async drop(config: TreeDropDataModel): Promise<void> {
    // apply drop
    try {
      await this.$confirm(
        "The entry will be moved. Continue?",
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "info",
        }
      );
    } catch (error) {
      return;
    }

    config.applyCallback();
    this.syncTree();
  }

  private async getProjectDocuments(projectId: string): Promise<void> {
    try {
      this.treeData = (
        await this.$store.dispatch("documentModule/getStructure", projectId)
      ).data;
    } catch (error) {
      console.error(error);
    }
  }

  async mounted(): Promise<void> {
    await this.getProjectDocuments(this.project);
    if (this.$route.params.id) {
      const routeId = +this.$route.params.id;
      this.goToDocumentById(routeId);
      this.openAndSelectNode(routeId);
    } else this.selectFirstDocument();
  }
}
